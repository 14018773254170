/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IPivotStyleProps, IPivotStyles } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';
import * as StyleConstants from '../Constants';
import { FontWeights } from '@fluentui/style-utilities';

export const PivotStyles = (props: IPivotStyleProps): Partial<IPivotStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { pivotBackgroundHover, pivotSelectedBorderBottom, pivotBackgroundPressed } = extendedSemanticColors;

  return {
    text: {
      color: extendedSemanticColors.pivotText || extendedSemanticColors.bodyText,
      fontSize: 14,
    },
    link: {
      selectors: {
        '&:hover': {
          background: pivotBackgroundHover,
        },
        '&:active': {
          background: pivotBackgroundPressed, // Add this line for pressed state background
        },
      },
      lineHeight: StyleConstants.pivotHeight,
      height: StyleConstants.pivotHeight,
    },
    linkIsSelected: {
      selectors: {
        '&:before': {
          borderBottom: `2px solid ${pivotSelectedBorderBottom}`,
          backgroundColor: 'transparent',
          transition: 'none',
        },
        '&:hover': {
          background: pivotBackgroundHover,
        },
        '&:active': {
          background: pivotBackgroundPressed, // Add this line for pressed state background
        },
        '& .ms-Pivot-text': {
          color: pivotSelectedBorderBottom || extendedSemanticColors.pivotText || extendedSemanticColors.bodyText,
        },
      },
      lineHeight: StyleConstants.pivotHeight,
      height: StyleConstants.pivotHeight,

      fontWeight: FontWeights.regular,
    },
    overflowMenuButton: {
      color: extendedSemanticColors.pivotText || extendedSemanticColors.bodyText
    },
    linkContent: [
      'ms-Pivot-linkContent',
      {
        flex: 'none',
      },
    ],
  };
};
