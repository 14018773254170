/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IDropdownStyles, IDropdownStyleProps } from '@fluentui/react';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const DropdownStyles = (props: IDropdownStyleProps): Partial<IDropdownStyles> => {
  const { disabled, theme } = props;

  if (!theme) {
    return {};
  }
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { inputBorderFocused, inputBorder, disabledBorder } = extendedSemanticColors;

  const dropdownItemStyle = {
    fontSize: 12,
    lineHeight: 16,
    padding: '5px 7px 3px',
    minHeight: 24,
    height: 24,
  };

  return {
    label: [{color: extendedSemanticColors.inputText}],
    title: [
      {
        color: semanticColors.inputText,
        borderColor: inputBorder,
        fontSize: 12,
        height: StyleConstants.dropdownHeight,
        lineHeight: StyleConstants.dropdownHeight - 2,
        padding: `1px 18px 1px 6px`,
        borderRadius: 2,
      },
    ],
    caretDownWrapper: [
      {
        height: 16,
        lineHeight: StyleConstants.dropdownHeight,
        right: 4,
        width: 16,
      },
      {
      ':hover .ms-Dropdown-title': {
        borderColor: semanticColors.inputBorderHovered,
        color: semanticColors.inputTextHovered
      }}
    ],
    dropdownItem: [dropdownItemStyle, { ':hover': { color: semanticColors.inputTextHovered}}],
    dropdownItemSelected: [{...dropdownItemStyle, color: semanticColors.menuItemText,     ':hover': {
      color: semanticColors.inputTextHovered, 
      backgroundColor: semanticColors.menuItemBackgroundHovered, 
    },}],

    dropdown: [
      {
        selectors: {
          '.ms-Dropdown-title:hover': {
            borderColor: semanticColors.inputBorderHovered,
            color: semanticColors.inputTextHovered
          },

          ':focus .ms-Dropdown-title': {
            color: semanticColors.inputText
          },
          ':focus::after, :focus': {
            borderColor: inputBorderFocused,
            borderWidth: 1,
            color: semanticColors.inputText
          },
          '.ms-Button-icon': {
            color: 'red',
          },
        },
      },
      disabled && {
        '.ms-Dropdown-title': {
          backgroundColor: `${semanticColors.disabledBackground} !important`,
          color: semanticColors.disabledText,
          borderColor: `${disabledBorder} !important`,
          fontSize: 12,
          height: StyleConstants.dropdownHeight,
          lineHeight: StyleConstants.dropdownHeight - 2,
          borderStyle: 'solid !important',
          borderWidth: '1px !important',
          padding: `1px 18px 1px 6px`,
          borderRadius: '2px !important',
        },
      },
      !disabled && {},
    ],
    subComponentStyles: {
      multiSelectItem: {root: {
        color: semanticColors.inputText
      }},
      panel: {

      },
      label: {
        root: { color: semanticColors.inputText }
      }
    }
  };
};
